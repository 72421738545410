// import $ from 'jquery';
import svg4everybody from 'svg4everybody';
import objectFitImages from 'object-fit-images';
import flexibility from 'flexibility';
import {TweenMax, Power2, TimelineLite} from "gsap/TweenMax";
import isMobile from './modules/common/is-mobile.js';
import './modules/common/smoothScroll.js';
import './modules/common/sp-menu.js';
// import './modules/common/lazyload.js';
import './modules/common/userAgent.js';
import './modules/common/tablefix.js';

flexibility(document.documentElement);//flexboxのie対応
objectFitImages();//object-fitのie対応
svg4everybody();//svgをieで表示

//電話番号スマホのみ掛ける
$("a[href*='tel']").on("click", function(e){
  if(!isMobile(768)){
    return false
  }
});

// const hoge = val => val + 1;
// console.log(hoge(1));
